import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { InternalLink } from '../components/link.js';
import compilerComplaintComic from '../images/compiler_complaint.png';
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Book reflection: `}<em parentName="h1">{`Programming Elm`}</em></h1>
    <p><em parentName="p">{`Programming Elm`}</em>{` by Jeremy Fairbank is a beginner-friendly book for learning Elm. I found it especially friendly coming from a JavaScript background. There are lots of helpful comparisons between JS and Elm implementations. `}</p>
    <p>{`Another impressive aspect is that the author walks you through practices for refactoring and scaling an app in `}<em parentName="p">{`Chapter 3: Refactor and Enhance Elm Applications`}</em>{` and `}<em parentName="p">{`Chapter 6: Build Larger Applications`}</em>{`. This information is practical and is clearly demonstrated via examples.`}</p>
    <p>{`Coding along while reading really allowed me to play around with the language and exercise its features. Following the examples helped me to retain the material but exploring beyond the samples in the book really helped connect the dots. The author does a great job of facilitating this absorption by sprinkling coding challenges along the way with answers available in the book `}<a parentName="p" {...{
        "href": "https://pragprog.com/titles/jfelm/source_code"
      }}>{`source code materials`}</a>{`.`}</p>
    <p>{`Let's dive into some first impressions of the language.`}</p>
    <h2><HashLink id="architecture" to="/programming-elm#architecture" mdxType="HashLink">{`Elm Architecture`}</HashLink></h2>
    <p>{`The Elm Architecture seems well thought-out. Separating the Model, Update, and View logic keeps the code organized, modular, maintainable and easily extensible. Nonetheless, I felt that building upon the architecture became a bit verbose and slightly cumbersome on occasion. I plan to develop more with Elm to further experiment with this hypothesis.`}</p>
    <h2><HashLink id="compiler" to="/programming-elm#compiler" mdxType="HashLink">{`Compiler`}</HashLink></h2>
    <img alt="Compiler complaint comic" title="https://xkcd.com/371/" src={compilerComplaintComic} width="100%" />
    <p>{`Don't you hate when a compiler yells at you for something and the message is so cryptic that you have no idea where to start? Sure, there are other debugging techniques but the point I'm trying to make is that I've never seen compilation errors as straight-forward and helpful as they are with the Elm compiler. `}</p>
    <p>{`That being said, at times these straight-forward messages can become quite ubiquitous. This is a net positive characteristic in my opinion. Making significant changes before recompiling can result in a rabbit hole of compilation errors, which is unfortunate. However, this becomes a way to incentivize the developer to compile often following smaller changes. The developer also gets the peace of mind that comes with the satisfaction of the compile-time checklist. Elm makes the promise that the users of an Elm app will experience `}<strong parentName="p">{`zero runtime exceptions`}</strong>{`.`}</p>
    <p>{`This is a pretty big deal and it's only possible with the help of Elm's type system.`}</p>
    <h2><HashLink id="type-system" to="/programming-elm#type-system" mdxType="HashLink">{`Type system`}</HashLink></h2>
    <p>{`Elm is a strongly-typed language and, so far, the type system gets an A+ from me. Being able to use types like `}<a parentName="p" {...{
        "href": "https://guide.elm-lang.org/error_handling/maybe.html"
      }}><inlineCode parentName="a">{`Maybe`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://guide.elm-lang.org/error_handling/result.html"
      }}><inlineCode parentName="a">{`Result`}</inlineCode></a>{` is a game-changer coming from a JavaScript background. No more forgetting to check the existance of a constant. Not to mention type constructors, exhaustive `}<a parentName="p" {...{
        "href": "https://guide.elm-lang.org/types/pattern_matching.html"
      }}>{`pattern matching`}</a>{` capabilities and `}<a parentName="p" {...{
        "href": "https://guide.elm-lang.org/effects/json.html"
      }}>{`JSON decoders`}</a>{`.`}</p>
    <h2><HashLink id="repl" to="/programming-elm#repl" mdxType="HashLink">{`REPL`}</HashLink></h2>
    <p>{`When starting to code in Elm it quickly becomes apparent that the Elm REPL provides a large productivity boost. There's no need to scaffold code and jump through hoops to make the compiler happy just to test the result of an expression. Fire up the Elm REPL, test away, and move on.`}</p>
    <h2><HashLink id="functional-first" to="/programming-elm#functional-first" mdxType="HashLink">{`Functional-first`}</HashLink></h2>
    <p>{`Using only pure functions and being side-effect-free comes with a great deal of benefits. Tests become easy to write, logic becomes easily composable, cognitive overhead is reduced, etc. If interested, check out more `}<InternalLink to="/road-to-fp" mdxType="InternalLink">{`thoughts on functional programming here`}</InternalLink>{`.`}</p>
    <h2><HashLink id="javascript" to="/programming-elm#javascript" mdxType="HashLink">{`JavaScript?`}</HashLink></h2>
    <p>{`One of the most attractive features of Elm is the interoperability with JavaScript. Many people have incrementally adopted the language into existing applications. These folks have set a precedent. Their successful adoption of Elm can be part of a case study to start programming in Elm. It's much easier to apply a `}<a parentName="p" {...{
        "href": "https://martinfowler.com/bliki/StranglerFigApplication.html"
      }}>{`strangler pattern`}</a>{` to a codebase than to try to re-write everything from scratch and flip the switch to a shiny new site.`}</p>
    <p>{`Overall, `}<em parentName="p">{`Programming Elm`}</em>{` is a worth-while read.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      